<template>
  <div>
    <a-card title="账户转账">
      <a-row  type="flex" justify="space-around">


        <a-col :span="20" :md="8" :xl="6" style="max-width: 256px;">
          <a-input-search v-model="searchForm.search" placeholder="名称, 备注,单号" allowClear @search="search" />
        </a-col>

        <a-col :span="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select
              ref="select"
              v-model="searchForm.turn_direct"
              @change="handleChange"
              style="min-width: 180px;"
          >

            <a-select-option value="out">转出账户</a-select-option>
            <a-select-option value="in">转入账户</a-select-option>
            <a-select-option value="">全部</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="11">
          <a-month-picker style="width: 100px;margin-right: 12px" valueFormat="YYYY-MM" :format="'YYYY-MM'"  @change="onChangePicker" placeholder="选择月份" />
          <a-button type="primary" @click="onExport">导出</a-button>

        </a-col>
        <a-col :span="3" style="float: right;">
          <a-button type="primary" icon="plus"  @click="openFormModal(form)">新增转账单</a-button>
        </a-col>

      </a-row>

      <a-row style="margin-top: 12px" id="tableContainer">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :rowClassName="rowClassName"
          :scroll="{ x: 1500, y: 700 }"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{
              value ? '激活' : '冻结'
            }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger" size="small" :disabled="item.is_void">{{
                  item.is_void ? '已作废' : '作废'
                }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal
      v-model="visible"
      :form="targetItem"
      :handlerItems="handlerItems"
      :accountsItems="accountsItems"
      @create="create"
      @update="update"
    />
  </div>
</template>

<script>
import { userOption, accountsOption } from '@/api/option';
import {
  accountTransferOrdersList,
  accountTransferOrdersVoid,
} from '@/api/finance';
import {saleExport} from "@/api/sale";
import {ChangePicker, exportExcel1} from "@/utils/excel";

export default {
  name: 'Warehouse',
  components: {
    FormModal: () => import('./FormModal.vue'),
  },
  data() {
    return {
      monthFormat : 'YYYY-MM',
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '80px',
          fixed: 'left',
          key: 'index',
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: '转出账户',
          width: '150px',

          dataIndex: 'out_account_name',
        },
        {
          title: '转出时间',
          width: '150px',
          dataIndex: 'transfer_out_time',
        },
        {
          title: '转入账户',
          width: '150px',
          dataIndex: 'in_account_name',
        },
        {
          title: '转入时间',
          width: '150px',
          dataIndex: 'transfer_in_time',
        },
        {
          title: '转账金额',
          width: '150px',
          dataIndex: 'transfer_amount',
        },
        {
          title: '手续费金额',
          width: '100px',
          dataIndex: 'service_charge_amount',
        },
        {
          title: '手续费支付方',
          width: '150px',
          dataIndex: 'service_charge_payer_display',
        },
        {
          title: '经手人',
          width: '150px',
          dataIndex: 'handler_name',
        },
        {
          title: '录入时间',
          width: '150px',
          dataIndex: 'create_time',
        },
        {
          title: '备注',
          width: '250px',
          dataIndex: 'remark',
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },

        },
      ],
      searchForm: { search: '',turn_direct:'',start_time:'',end_time:'', page: 1, page_size: 50 },
      pagination: { current: 1, total: 0, pageSize: 50 }, // 生效的是这个
      loading: false,
      items: [],
      handlerItems: [],
      accountsItems: [],
      visible: false,
      targetItem: {},
      form: {},
      importLoading: false,
    };
  },
  computed: {

  },
  methods: {
    toggleVisibility() {
      // 发送一个事件给父组件，要求它改变 visible 的值
      this.$emit('update:visible', !this.visible);
    },

    onChangePicker(a,b){
      if (a == null) {
        this.searchForm.start_date =  a;
        this.searchForm.end_date = a;
        this.search();
        return;
      }
      const {first_day1,last_day1} = ChangePicker(a,b)
      this.searchForm.start_date =  first_day1;
      this.searchForm.end_date = last_day1;
      this.search();
    },

    onExport() {
        import("../../../utils/xlsx.js").then((excel) => {
          //tHeader 为导出后的表头
          let tHeader = [], filterVal = [], list = []
          tHeader = ["转出账户", "转出时间", "转入账户", "转入时间", "转账金额", "手续费金额", "手续费支付方", "经手人", "发生日期",  "备注"];
          filterVal =["out_account_name", "transfer_out_time", "in_account_name", "transfer_in_time", "transfer_amount", "service_charge_amount", "service_charge_payer_display", "handler_name", "handle_time", "remark"];
          list = this.items
          list.reverse();
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename:`账户转账${this.getCurrentDate()}`,
            autoWidth: true,
            bookType: "xlsx",
          });
        });

    },
    getCurrentDate() {
      let today = new Date();
      let year = today.getFullYear();
      let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1，并且补0到两位数
      let day = today.getDate().toString().padStart(2, '0'); // 补0到两位数
      let formattedDate = `${year}${month}${day}`; // 拼接年月日
      return formattedDate;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
          filterVal.map((j) => {
            if (j === "timestamp") {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
      );
    },
    handleChange(value){
      console.log(`selected ${value}`);
      this.search()

    },
    //设置某行tr背景色
    rowClassName(record, index) {
      if (record.is_void === true) return 'light-yellow';
      if (record.is_active === false) return 'light-gray';
    },
    initialize() {
      // document.getElementById('tableContainer').style.height = '600px';
      this.list();
    },
    list() {
      this.loading = true;
      accountTransferOrdersList(this.searchForm)
        .then(data => {
          this.pagination.total = data.count;
          this.items = data.results;

          this.exportItems=[]
          this.items.forEach(item=>{
            const obj=item
            obj.transfer_amount=parseFloat(item.transfer_amount)
            obj.service_charge_amount=parseFloat(item.service_charge_amount) // 手续费
            this.exportItems.push(obj)
          })

        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order === 'descend' ? '-' : ''}${
        sorter.field
      }`;
      this.list();
    },
    create(item) {
      // this.items.splice(0, 0, item);
      this.list();
    },
    update(item) {
      this.items.splice(
        this.items.findIndex(i => i.id === item.id),
        1,
        item
      );
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    openFormModal(item) {
      userOption({ page_size: 999999, is_active: true }).then(data => {
        this.handlerItems = data.results;
      });
      accountsOption({ page_size: 999999, is_active: true }).then(data => {
        this.accountsItems = data.results;
      });
      this.targetItem = { ...item };
      this.visible = true;
    },
    voidItem(item) {
      accountTransferOrdersVoid({ id: item.id }).then(() => {
        this.$message.success('作废成功');
        this.list();
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style lang="css" scoped>
/deep/.ant-table-tbody .light-yellow {
  background-color: #f5f4f3 !important;
}
/deep/.ant-table-tbody .second {
  background-color: #071641 !important;
}
</style>
