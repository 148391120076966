<template>
  <div>
    <a-card title="销售单详情">
      <a-button slot="extra" type="primary" style="margin-right: 8px;" ghost  @click="printFun('printContent1')"> <a-icon type="printer" />打印单</a-button>
      <a-button slot="extra" type="primary" style="margin-right: 8px;" ghost  @click="printFun('printContent')"> <a-icon type="printer" />打印</a-button>
      <a-button slot="extra" type="primary" ghost @click="goPre"> <a-icon type="left" />返回</a-button>

      <section id="printContent1" class="print-section no-print">
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="销售编号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="销售方">
              {{ title }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ info.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="仓库">
              {{ info.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="经手人">
              {{ info.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="出库日期">
              {{ info.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="其他费用">
              {{ info.other_amount }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
          <!-- <a-divider orientation="left" style="margin-top: 30px;">结算账户信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columnsAccount"
            :data-source="info.sales_account_items"
            :pagination="false" /> -->
          <a-divider orientation="left" style="margin-top: 30px;">产品信息</a-divider>
          <a-table
              rowKey="id"
              size="middle"
              :columns="columns1"
              :data-source="info.sales_goods_items"
              :pagination="false" />
        </a-spin>
      </section>

      <section id="printContent" class="print-section" >
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="销售编号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="销售方">
              {{ title }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ info.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="仓库">
              {{ info.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="经手人">
              {{ info.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="出库日期">
              {{ info.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="其他费用">
              {{ info.other_amount }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
          <!-- <a-divider orientation="left" style="margin-top: 30px;">结算账户信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columnsAccount"
            :data-source="info.sales_account_items"
            :pagination="false" /> -->
          <a-divider orientation="left" style="margin-top: 30px;">产品信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columns"
            :data-source="info.sales_goods_items"
            :pagination="false" />
        </a-spin>
      </section>

    </a-card>
  </div>
</template>

<script>
  import { getInfo } from "@/api/enterprise";
  import { saleOrderDetail } from '@/api/sale'
  import JsBarcode from 'jsbarcode'
  import NP from 'number-precision'

export default {
    data() {
      return {
        printObj: {
          id: "printContent1",
          preview: false,
          previewTitle: 'print Title', // The title of the preview window. The default is 打印预览
          popTitle: 'good print',
          extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
          extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
          previewBeforeOpenCallback (vue) {
            console.log('正在加载预览窗口')
          },
          previewOpenCallback (vue) {
            console.log('已经加载完预览窗口')
          },
          beforeOpenCallback (vue) {
            vue.printLoading = true
            console.log('打开之前')
          },
          openCallback (vue) {
            vue.printLoading = false
            console.log('执行了打印')
          },
          closeCallback (vue) {
            console.log('关闭了打印工具')
          }
        },
        title: '',
        loading: false,
        materialLoading: false,
        receiptOrder: undefined,
        info: {},
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '产品编号',
            dataIndex: 'goods_number',
            key: 'goods_number',
            width: 150,
          },
          {
            title: '产品名称',
            dataIndex: 'goods_name',
            key: 'goods_name',
            width: 150,
          },
          {
            title: '产品规格',
            dataIndex: 'goods_spec',
            key: 'goods_spec',
            width: 150,
          },
          {
            title: '销售数量',
            dataIndex: 'sales_quantity',
            key: 'sales_quantity',
            width: 120,
          },
          {
            title: '销售单位',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 80,
          },
          {
            title: '单价(元)',
            dataIndex: 'sales_price',
            key: 'sales_price',
            width: 120,
          },
          {
            title: '合计金额(元)',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 200,
            customRender: (value, item) => {
              if (item.isTotal) return value;
              value = NP.times(item.sales_quantity, item.sales_price);
              return item.id ? NP.round(value, 2) : ''
            },
          }
        ],
        columns1: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '产品编号',
            dataIndex: 'goods_number',
            key: 'goods_number',
            width: 150,
          },
          {
            title: '产品名称',
            dataIndex: 'goods_name',
            key: 'goods_name',
            width: 150,
          },
          {
            title: '产品规格',
            dataIndex: 'goods_spec',
            key: 'goods_spec',
            width: 150,
          },
          {
            title: '销售数量',
            dataIndex: 'sales_quantity',
            key: 'sales_quantity',
            width: 120,
          },
          {
            title: '销售单位',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 80,
          },
          // {
          //   title: '单价(元)',
          //   dataIndex: 'sales_price',
          //   key: 'sales_price',
          //   width: 120,
          // },
          // {
          //   title: '合计金额(元)',
          //   dataIndex: 'totalAmount',
          //   key: 'totalAmount',
          //   width: 200,
          //   customRender: (value, item) => {
          //     if (item.isTotal) return value;
          //     value = NP.times(item.sales_quantity, item.sales_price);
          //     return item.id ? NP.round(value, 2) : ''
          //   },
          // }
        ],


        columnsAccount: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '结算账户',
            dataIndex: 'account_name',
            key: 'account_name',
            width: 200,
          },
          {
            title: '付款金额',
            dataIndex: 'collection_amount',
            key: 'collection_amount',
            width: 200,
          }
        ],
      }
    },
    async created(){
      this.initData();
      const { data } = await getInfo();
      this.title = data.title;
      console.log(6666,this.title)
    },
    methods: {
       printFun(id) {
         let subOutputRankPrint= document.getElementById(id);
         console.log(subOutputRankPrint.innerHTML);
         let newContent =subOutputRankPrint.innerHTML;
         let oldcontent = document.body.innerHTML;
         document.title = this.info.client_name+'销售单';
         document.body.innerHTML = newContent;
         window.print();
         window.location.reload();
         document.body.innerHTML = oldcontent;
         return false;


      },
      goPre(){
        this.$router.push({ path: '/sale/sale_record', query: { search:this.$route.query.search} });
      },
      getJsBarcode(number) {
        JsBarcode("#barcode", number, {
          lineColor: '#000',
          width: 2,
          height: 40,
          displayValue: true
        });
      },
      initData() {
        this.loading = true;
        saleOrderDetail({ id: this.$route.query.id }).then(data => {
          this.info = data;
          this.info.sales_account_items = [
            ...this.info.sales_account_items,
            {
              id: '-1',
              isTotal: true,
              collection_amount: this.info.collection_amount,
            },
          ];
          this.info.sales_goods_items = [
            ...this.info.sales_goods_items,
            {
              id: '-1',
              isTotal: true,
              sales_quantity: this.info.total_quantity,
              totalAmount: this.info.total_amount,
            },
          ];
          this.getJsBarcode(data.number)
        }).finally(() => {
          this.loading = false;
        });
      },
    },
    mounted() {
      this.initData();
    },
  }
</script>
<style>
.no-print {
  display: none;
}
@media print {
  .no-print {
    display: inline; /* 或者其他适合你布局的显示方式 */
  }
}

/* 禁用默认的页眉和页脚 */
@page {
  @top-center { content: ''; }
  @bottom-center { content: ''; }
  @top-left { content: ''; }
  @bottom-left { content: ''; }
  @top-right { content: ''; }
  @bottom-right { content: ''; }
}

.print-section {
  /* 设置默认不显示标题 */
  counter-reset: page;
}

@media print {
  .print-section::before {
    /* 在打印时显示标题 */
    content: "销售单";
    display: block;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
}

.print-section::after {
  /* 在打印时显示页脚 */
  content: "Page " counter(page) " of " counter(pages);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #999;
}
/* 隐藏不需要打印的元素 */
.no-print {
  display: none !important;
}

@media print {
  /* 隐藏导航栏、按钮等非打印内容 */
  .no-print,
  .no-print * {
    display: none !important;
  }

  /* 隐藏路由信息 */
  [class*="vue-router-link"] {
    display: none !important;
  }

  /* 隐藏时间信息 */
  time {
    display: none !important;
  }
}

</style>
