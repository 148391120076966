<template>
  <div>
    <keep-alive>
      <a-card title="销售出库">
        <a-row :gutter="16">

          <a-col :span="24" :md="12" :xl="6">
            <a-input-search v-model="searchForm.search" placeholder="单号,供应商编号,名称" allowClear @search="search" />
          </a-col>

          <a-col :span="8">

            <a-month-picker style="width: 100px;margin-right: 12px"  v-model="searchForm.date" valueFormat="YYYY-MM" :format="'YYYY-MM'"  @change="onChangePicker" placeholder="选择月份" />
            <a-button type="primary" @click="onExport">导出</a-button>
          </a-col>

          <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
            <a-range-picker @change="onChangePicker" />
          </a-col> -->
          <a-col :span="3"> <div>销售总数量：{{total_quantitys.toFixed(2)}}</div></a-col>

          <a-col :span="3"> <div>销售总金额： {{ total_amounts.toFixed(2) }}</div></a-col>
        </a-row>


        <a-row style="margin-top: 24px">
          <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
                   @change="tableChange" :scroll="{ x: 1700}">
            <div slot="goods_name" slot-scope="value, item">
              {{item.sales_goods_items[0].goods_name}}

            </div>
            <div slot="action" slot-scope="value, item">
              <a-button-group size="small">
                <a-button size="small" @click="detial(item)">详情</a-button>
                <a-popconfirm title="确定作废吗" @confirm="voidItem(item)">
                  <a-button v-if="!item.is_void" type="danger" size="small">作废</a-button>
                  <a-button v-else disabled size="small">已作废</a-button>
                </a-popconfirm>
              </a-button-group>
            </div>
          </a-table>
        </a-row>
      </a-card>
    </keep-alive>

  </div>
</template>

<script>
  import { exportExcel1 } from '@/utils/excel'
  import { ChangePicker } from '@/utils/excel'
  import { saleOrderList, saleOrdersVoid, saleExport } from '@/api/sale'
  export default {
    name: 'SaleRecord',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '销售编号',
            dataIndex: 'number',
            sorter: true,
            width: 180

          },
          {
            title: '客户',
            dataIndex: 'client_name',
            width: 190
          },
          {
            title: '商品名称',
            dataIndex: 'goods_name',
            scopedSlots: { customRender: 'goods_name' },
            width: 190
          },
          {
            title: '经手人',
            dataIndex: 'handler_name',
            width: 100

          },
          {
            title: '发生日期',
            dataIndex: 'handle_time',
            width: 150
          },
          {
            title: '录入时间',
            dataIndex: 'create_time',
            width: 170
          },
          {
            title: '销售总数量',
            dataIndex: 'total_quantity',
            width: 120
          },
          {
            title: '销售总金额',
            dataIndex: 'total_amount',
            width: 120
          },
          {
            title: '收款金额',
            dataIndex: 'collection_amount',
            width: 120
          },
          {
            title: '其他费用',
            dataIndex: 'other_amount',
          },
          {
            title: '操作',
            dataIndex: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' },
            width: 200
          },
        ],
        searchForm: { page: 1, page_size: 16 ,search:'',date:''},
        pagination: { current: 1, total: 0, pageSize: 50 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
        total_amounts : 0,
        total_quantitys : 0,
      };
    },
    computed: {

    },

    methods: {

      onChangePicker(a,b){
        if (a == null) {
          this.searchForm.start_date =  a;
          this.searchForm.end_date = a;
          this.search();
          return;
        }
        const {first_day1,last_day1} = ChangePicker(a,b)
        this.searchForm.start_date =  first_day1;
        this.searchForm.end_date = last_day1;
        this.search();
      },
      onExport1() {
        saleExport({ ex_type: "sales_orders" }).then(data => {
          let contentDisposition = data.headers['content-disposition'];
          let regex = /filename\s*=\s*([^;]+)/;
          let matches = contentDisposition.match(regex);
          let filename = matches && matches[1].trim();
          exportExcel1(data.data, filename);
        });
      },


      onExport() {
        import("../../../utils/xlsx.js").then((excel) => {
          //tHeader 为导出后的表头
          let tHeader = [], filterVal = [], list = []

          tHeader = [ "销售编号", "客户",          "商品名称", "经手人",         "录入时间",    "发生日期",    "销售总数量",        "销售总金额",    "收款金额",         "其他费用",     "备注", ];
          filterVal =[ "number", "client_name", "goods_name", "handler_name", "create_time","handle_time", "total_quantity", "total_amount", "collection_amount", "other_amount","remark", ];
          list = this.exportItems
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename:`销售出库列表`+ this.getCurrentDate(),
            autoWidth: true,
            bookType: "xlsx",
          });
        });

      },

      getCurrentDate() {
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1，并且补0到两位数
        let day = today.getDate().toString().padStart(2, '0'); // 补0到两位数
        let formattedDate = `${year}${month}${day}`; // 拼接年月日
        return formattedDate;
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
            filterVal.map((j) => {
              if (j === "timestamp") {
                return parseTime(v[j]);
              } else {
                return v[j];
              }
            })
        );
      },

      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        saleOrderList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
          this.total_amounts = 0
          this.total_quantitys = 0
          this.exportItems=[]
          this.items.forEach(item=>{
            const obj=item
            obj.collection_amount=parseFloat(item.collection_amount)
            obj.other_amount=parseFloat(item.other_amount)
            obj.total_amount=parseFloat(item.total_amount)
            obj.goods_name=item.sales_goods_items[0].goods_name

            this.total_amounts = this.total_amounts + obj.total_amount
            this.total_quantitys = this.total_quantitys + obj.total_quantity
            this.exportItems.push(obj)
          })

        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },

      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      detial(item) {
        this.$router.push({ path: '/sale/sale_record_detail', query: { id: item.id ,search:this.searchForm} });
      },
      voidItem(item) {
        saleOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {

      let searchParam=this.$route.query.search

      if(searchParam && (typeof (searchParam.search)=="string"||searchParam.date)){
        this.searchForm.search=searchParam.search||''
        this.searchForm.date=searchParam.date||''
        this.searchForm.start_date=searchParam.start_date||''
        this.searchForm.end_date=searchParam.end_date||''

      }
      this.initialize();
    },
  }
</script>
